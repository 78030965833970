import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["tujuan_anak", "template", "cekdetail", "xjnsdetail"]
  
  initialize() {
    this.xjnsdetailTarget.hidden = true
  }

  tambah_anak_klik(event){
      event.preventDefault()
      var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
      var chars = {'$':'<tr>','£':'<td>','!':'</td>', '^':'</tr>'}
      var hasil = content.replace(/[$£!^]/g, m => chars[m])

      this.tujuan_anakTarget.insertAdjacentHTML('beforebegin', hasil) 

   }

   hapus_anak_klik(event){
    event.preventDefault()
      let item = event.target.closest("tr")
      item.querySelector("input[name*='_destroy']").value = 1   
      item.style.display = 'none'
   } 

   prosescekdetail(){
     event.preventDefault()
     this.xjnsdetailTarget.hidden = true

     if (this.cekdetailTarget.checked) {
        this.xjnsdetailTarget.hidden = false
     }

   }

}  