import { Controller } from "stimulus"
import AutoNumeric from "autonumeric"

export default class extends Controller {

  static targets = ["tujuan_anak", "template", "total", "tottotal"]

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    this.totalTargets.forEach((element, index) => {
      new AutoNumeric(element, autoNumericOptionsVND)        
    }) 
    new AutoNumeric(this.tottotalTarget, autoNumericOptionsVND)
    this.hitungtotal()
  }

  buat_angka(){
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }
    this.totalTargets.forEach((element, index) => {
      if (AutoNumeric.getAutoNumericElement(element) === null) {  
         new AutoNumeric(element, autoNumericOptionsVND)
      }
    })     
  }

  tambah_anak_klik(event){
      event.preventDefault()
      var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
      var chars = {'$':'<tr>','£':'<td>','!':'</td>', '^':'</tr>'}
      var hasil = content.replace(/[$£!^]/g, m => chars[m])

      this.tujuan_anakTarget.insertAdjacentHTML('beforebegin', hasil)
      this.buat_angka()  

   }

   hapus_anak_klik(event){
    event.preventDefault()
      let item = event.target.closest("tr")
      item.querySelector("input[name*='_destroy']").value = 1
      AutoNumeric.getAutoNumericElement(item.querySelector("input[name*='total']")).set(0)    
      item.style.display = 'none'
      this.hitungtotal()
   }  

   hitungtotal(){     
    this.totdebet = 0

    this.totalTargets.forEach((element, index) => {
       this.totdebet += this.cariIsi(element) || 0      
    })  
 
    AutoNumeric.getAutoNumericElement(this.tottotalTarget).set(this.totdebet)

   }  
   
   cariIsi(el){
     return AutoNumeric.getAutoNumericElement(el).getNumber()
   }

}  